import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CLIENT_COMPANY_ELEMENT,
    REDUCE_CLIENT_COMPANY,
    QUERY_CLIENT_COMPANY_REFS,
    REDUCE_QUERY_ELEMENT,
    REDUCE_CLIENT_COMPANIES,
    REDUCE_PARAMETERS,
    REDUCE_PARAMETER,
    REDUCE_ENTITY,
    REDUCE_CLIENT_COMPANY_FNOL,
    REDUCE_CLIENT_COMPANY_FNOL_ELEMENT,
    REDUCE_CLIENT_COMPANY_POLICIES,
    REDUCE_CLIENT_COMPANY_QUOTES,
    REDUCE_CLIENT_COMPANY_POLICY,
    REDUCE_CLIENT_COMPANY_BROKER,
    REDUCE_CLIENT_COMPANY_PRODUCTS
} from '../../constants/actions/clientCompany';

const initialState = {
    entity: null,
    parameters: [],
    clientCompany: null,
    clientCompanyRefs: null,
    query: {
        skip: 0,
        orderByDesc: true
    },
    clientCompanies: [],
    clientCompanyFnol: {},
    clientCompanyPolicies: [],
    clientCompanyPolicy: null,
    clientCompanyQuotes: [],
    broker: null,
    products: []
};

function clientCompanyReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case REDUCE_PARAMETER:
            let parameterIndex = 0;
            if (state.parameters.some(x => x.code === action.payload.code)) {
                parameterIndex = state.parameters.findIndex(x => x.code === action.payload.code);
            } else {
                parameterIndex = state.parameters.length;
            }
            return dotProp.set(state, `parameters.${parameterIndex}`, { code: action.payload.code, value: action.payload.value });
        case REDUCE_PARAMETERS:
            return dotProp.set(state, 'parameters', action.payload);
        case REDUCE_CLIENT_COMPANY_ELEMENT:
            return dotProp.set(state, `clientCompany.${action.payload.code}`, action.payload.value);
        case REDUCE_CLIENT_COMPANY:
            return dotProp.set(state, 'clientCompany', action.payload);
        case QUERY_CLIENT_COMPANY_REFS:
            return dotProp.set(state, 'clientCompanyRefs', action.payload);
        case REDUCE_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_CLIENT_COMPANIES:
            return dotProp.set(state, 'clientCompanies', action.payload);
        case REDUCE_CLIENT_COMPANY_FNOL:
            return dotProp.set(state, 'clientCompanyFnol', action.payload);
        case REDUCE_CLIENT_COMPANY_FNOL_ELEMENT:
            return dotProp.set(state, 'clientCompanyFnol', { ...state.clientCompanyFnol, [action.payload.code]: action.payload.value });
        case REDUCE_CLIENT_COMPANY_POLICIES:
            return dotProp.set(state, 'clientCompanyPolicies', action.payload);
        case REDUCE_CLIENT_COMPANY_POLICY:
            return dotProp.set(state, 'clientCompanyPolicy', action.payload);
        case REDUCE_CLIENT_COMPANY_QUOTES:
            return dotProp.set(state, 'clientCompanyQuotes', action.payload);
        case REDUCE_CLIENT_COMPANY_BROKER:
            return dotProp.set(state, 'broker', action.payload);
        case REDUCE_CLIENT_COMPANY_PRODUCTS:
            return dotProp.set(state, 'products', action.payload);
        default:
            return state;
    }
}

export default clientCompanyReducer;
