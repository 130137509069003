import dotProp from 'dot-prop-immutable';

import {
    REDUCE_TEMPLATE
} from '../../constants/actions/template';

const initialState = {
    templateConfig: {}
};

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_TEMPLATE:
            const configObj = { "name": action.payload.templateCode, "countryCode": action.payload.countryCode };
            const elements = action.payload.templateProperties ?? [];
            elements.forEach(ele => {
                configObj[`${ele.code}`] = ele.value;
            });
            return dotProp.set(state, 'templateConfig', { ...configObj, productName: action.payload.productCode });
        default:
            return state;
    }
}

export default templateReducer;
