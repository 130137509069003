import dotProp from 'dot-prop-immutable';

import {
    REDUCE_SECURITY_ELEMENT,
    REDUCE_SECURITY,
    ENABLE_MFA,
    DISABLE_MFA,
    REDUCE_PARAMETER,
    REDUCE_PARAMETERS
} from '../../constants/actions/security';

const initialState = {
    email: null,
    password: null,
    reference: null,
    account: null,
    user: null,
    mfa: false,
    mfaCode: null,
    parameters: [],
};

function contextReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_SECURITY_ELEMENT:
            return dotProp.set(state, action.payload.code, action.payload.value);
        case REDUCE_SECURITY:
            return (state = initialState);
        case ENABLE_MFA:
            return dotProp.set(state, 'mfa', true);
        case DISABLE_MFA:
            return dotProp.set(state, 'mfa', false);
        case REDUCE_PARAMETER:
            if (!action.payload) return state;

            let parameterIndex = 0;
            if (state.parameters.some(x => x.code === action.payload.code)) {
                parameterIndex = state.parameters.findIndex(x => x.code === action.payload.code);
            } else {
                parameterIndex = state.parameters.length;
            }
            return dotProp.set(state, `parameters.${parameterIndex}`, { code: action.payload.code, value: action.payload.value });
        case REDUCE_PARAMETERS:
            return dotProp.set(state, `parameters`, action.payload);
        default:
            return state;
    }
}

export default contextReducer;
