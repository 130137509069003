import dotProp from 'dot-prop-immutable';

import {
    REDUCE_BROKERCOMPANY_SETTINGS,
} from '../../constants/actions/brokerCompanySettings';

const initialState = {
    brokerCompanySettings: null,
};

function brokerCompanySettingsReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_BROKERCOMPANY_SETTINGS:
            return dotProp.set(state, 'brokerCompanySettings', action.payload);     
        default:
            return state;
    }
}

export default brokerCompanySettingsReducer;
