import {
    REDUCE_USER_SETTINGS
} from '../../constants/actions/user';

const initialState = {
    brokerageIndustryType: null,
};

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_USER_SETTINGS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default templateReducer;
