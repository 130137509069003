import dotProp from 'dot-prop-immutable';
import { REDUCE_PAYMENT_EMAIL, REDUCE_PAYMENT_OPTION, REDUCE_PAYMENT_OPTION_ELEMENT } from '../../constants/actions/payment';

const initialState = {
	paymentEmail: null,
	paymentOption: null,
};

function paymentReducer(state = initialState, action) {
	switch (action.type) {
		case REDUCE_PAYMENT_OPTION:
			return dotProp.set(state, 'paymentOption', action.payload);
		case REDUCE_PAYMENT_OPTION_ELEMENT:
			const { path, value } = action.payload;
			return dotProp.set(state, `paymentOption.${path}`, value);
		case REDUCE_PAYMENT_EMAIL:
			return dotProp.set(state, 'paymentEmail', action.payload);
		default:
			return state;
	}
}

export default paymentReducer;