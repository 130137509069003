import React from 'react';
import { Helmet } from 'react-helmet';

class StoreFrontHelmet extends React.Component {
    componentDidMount() {
        this.props.context?.configuration?.gtm && this.injectGtm()
    }

    injectGtm = () => {
        const body = document.getElementsByTagName('body')[0];
        const openComment = document.createComment(' Google Tag Manager (noscript) ');
        const noscript =  document.createElement('noscript');
        const closeComment = document.createComment(' End Google Tag Manager (noscript) ');
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this.props.context.configuration.gtm}`);
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.setAttribute('style', 'display:none;visibility:hidden');

        body.prepend(openComment)
        body.insertBefore(noscript, openComment.nextSibling);
        body.insertBefore(closeComment, noscript.nextSibling);
        noscript.appendChild(iframe);
    }

    getTags = () => {
        return (
            <Helmet>
                <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet" />
                <script src="https://calendly.com/assets/external/widget.js" type="text/javascript" />

                {this.props.context &&
                    this.props.context.configuration &&
                    this.props.context.configuration.gtm &&                                       
                    <script>
                        {
                            `
                            (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${this.props.context.configuration.gtm}');
                            `
                        }
                    </script>                    
                }

                {this.props.context &&
                    this.props.context.configuration &&
                    this.props.context.configuration.hotjar &&
                    <script async type={`text/javascript`}>
                        {`
                            (function(h,o,t,j,a,r){
                                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                                h._hjSettings={hjid:${this.props.context.configuration.hotjar},hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `}
                    </script>
                }
                {this.props.context &&
                    this.props.context.storefront &&
                    this.props.context.storefront.customAnalytics &&
                    <script async type={`text/javascript`}>
                        {`
                           ${this.props.context?.storefront?.customAnalytics}
                        `}
                    </script>
                }
            </Helmet>
        );
    }

    render() {
        return this.getTags();
    }
}

export default StoreFrontHelmet;