import React from 'react';

import { Container, Colors } from 'metrix-common';
import Routes from '../../components/Routes';
import SupportPartner from '../../components/SupportPartner';
import StoreFrontHelmet from '../../components/Helmet';

class App extends React.Component {
    render() {
        const { subfolderPrefix } = this.props.context;

        if (!window.matchMedia(`(min-width: 576px)`).matches) {
            let viewport = document.getElementsByTagName(`META`).viewport;
            if (!/maximum-scale/.test(viewport.content)) {
                viewport.content += `, maximum-scale=1`;
            }
        }

        window.zESettings = {
            webWidget: {
                color: {
                    theme: `${Colors.Primary}`
                }
            }
        }

        if (window.location.pathname === `${subfolderPrefix}/workflow`) {
            window.zESettings.webWidget.offset = {
                vertical: '50px',
                mobile: {
                    vertical: '85px'
                }
            }
        }

        if (window.location.pathname === `${subfolderPrefix}/quote`) {
            window.zESettings.webWidget.offset = {
                vertical: '80px',
                mobile: {
                    vertical: '85px'
                }
            }
        }

        return (
        <Container>
            <StoreFrontHelmet {...this.props} />
            <Routes {...this.props} />
            <SupportPartner {...this.props} />
        </Container>
        );
    }
}

export default App;