import dotProp from 'dot-prop-immutable';

import {
    REDUCE_AGENT
} from '../../constants/actions/template';

const initialState = {
    agent: ""
};

function templateReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_AGENT:
            return dotProp.set(state, 'agent', action.payload);
        default:
            return state;
    }
}

export default templateReducer;
