import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CLIENT_DASHBOARD,
    REDUCE_CLIENT_AGENT,
    REDUCE_CLIENT_AGENT_ELEMENTS,
    REDUCE_CLIENT_ENTITY,
} from '../../constants/actions/client';

const initialState = {
    entity: null,
    agent: null,
    dashboard: null,
};

function clientReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_CLIENT_DASHBOARD:
            return dotProp.set(state, 'dashboard', action.payload);
        case REDUCE_CLIENT_AGENT:
            return dotProp.set(state, 'agent', action.payload);
        case REDUCE_CLIENT_AGENT_ELEMENTS:
            return dotProp.set(state, 'agent', { ...state.agent, [action.payload.code]: action.payload.value });
        case REDUCE_CLIENT_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        default:
            return state;
    }
}

export default clientReducer;
