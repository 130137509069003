import dotProp from 'dot-prop-immutable';

import {
    CLEAR_BROKERCOMPANY_SIGNUP,
    REDUCE_BROKERCOMPANY_SIGNUP_ELEMENT
} from '../../constants/actions/brokerCompanySignup';

const initialState = {
    brokerCompanySignup: {}
};

function brokerCompanySignupReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_BROKERCOMPANY_SIGNUP:
            return dotProp.set(state, 'brokerCompanySignup', {});
        case REDUCE_BROKERCOMPANY_SIGNUP_ELEMENT:
            return dotProp.set(state, `brokerCompanySignup.${action.payload.code}`, action.payload.value);
        default:
            return state;
    }
}

export default brokerCompanySignupReducer;
