import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
    withSagas
} from 'metrix-common'

import storefrontSaga from '../../sagas/storefront/saga';

import getStorefrontConfiguration from '../../actions/storefront/getStorefrontConfiguration';
import reduceStorefrontConfiguration from '../../actions/storefront/reduceStorefrontConfiguration';
import { selectStorefrontConfiguration } from '../../selectors/storefront/selectStorefrontConfiguration';

class StoreFrontProvider extends React.Component {

    componentDidMount() {
        if (this.props.context.offline) {
            this.props.doReduceStorefrontConfiguration({});
        } else {
            this.props.doGetStorefrontConfiguration();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.context.storefront) {
            return true
        }
    }

    propagatePropsToChildren(children, newProps) {
        return React.Children.map(children, child => {
            if (child) {
                let childProps = {};

                if (React.isValidElement(child)) {
                    childProps = newProps;
                }

                //Don't propagate to HTML elements (e.g. div)
                if (child.props && typeof child.type !== 'string') {
                    return React.cloneElement(child, childProps);
                }

                return child;
            }
        });
    }

    render() {
        const context = {
            ...this.props.context,
            storefront: this.props.storefront || {}
        };
        const child = this.propagatePropsToChildren(this.props.children, { context })[0];
        if (context.storefront && Object.keys(context.storefront).length) {
            return (
                <React.Fragment>
                    {
                        child
                    }
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = createStructuredSelector({
    storefront: selectStorefrontConfiguration()
});

function mapDispatchToProps(dispatch) {
  return {
      doGetStorefrontConfiguration: () => dispatch(getStorefrontConfiguration()),
      doReduceStorefrontConfiguration: (payload) => dispatch(reduceStorefrontConfiguration(payload))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = withSagas({ key: 'storefront', saga: storefrontSaga });

export default compose(withConnect, withSaga)(StoreFrontProvider);
